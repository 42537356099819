import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(Backend)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "uk",
    returnObjects: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: [
      "translation_v3.json",
      "translation_news_p1_v2.json",
      "translation_news_p2_v1.json",
      "index_news_v2.json",
      "translation_kwlb_v1.json",
    ],
    defaultNS: "translation_v3.json",
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}",
      // addPath: "/locales/{{lng}}/translation_news_v1.json",
    },
  });

export default i18n;
