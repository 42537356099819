import { createSlice } from "@reduxjs/toolkit";

export const kwlbSlice = createSlice({
  name: "kwlbItem",
  initialState: {
    item: null,
  },
  reducers: {
    updateKwlbItem: (state, action) => {
      state.item = action.payload;
    },
  },
});

export const { updateKwlbItem } = kwlbSlice.actions;

export default kwlbSlice.reducer;
