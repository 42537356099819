import React from "react";
import "./loader.scss";

const SuspenseLoader = ({color, width, height, widthInner, heightInner}) => {
  const wrapperStyle = {
    width: width || "100vw",
    height: height || "100vh",
    backgroundColor: color || "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const style = {
    width: widthInner || "100px",
    height: heightInner || "100px",
    backgroundColor: color || "black",    
  };


  return (
    <div style={wrapperStyle}>
      <div className="LoaderContainer" style={style}>
        <div className="div-1"></div>
        <div className="div-2"></div>
        <div className="div-3"></div>
        <div className="div-4"></div>
        <div className="div-5">
          <span>FAIR</span>
          <br />
          <span>CONSULTING</span>
        </div>
      </div>
    </div>
  );
};

export default SuspenseLoader;
