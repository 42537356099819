import { createSlice } from "@reduxjs/toolkit";

export const mediaSlice = createSlice({
  name: "screenSize",
  initialState: {
    size: "large",
  },
  reducers: {
    updateScreenSize: (state, action) => {
      state.size = action.payload;
    },
  },
});

export const { updateScreenSize } = mediaSlice.actions;

export default mediaSlice.reducer;
