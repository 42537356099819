import { createSlice } from "@reduxjs/toolkit";

export const activeCitySlice = createSlice({
  name: "activeCity",
  initialState: {
    city: "Warsaw",
  },
  reducers: {
    updateActiveCity: (state, action) => {
      state.city = action.payload;
    },
  },
});

export const { updateActiveCity } = activeCitySlice.actions;

export default activeCitySlice.reducer;
