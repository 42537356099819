import { configureStore } from "@reduxjs/toolkit";
import mediaReducer from "./slices/mediaSlice";
import kwlbSlice from "./slices/kwlbSlice";
import formModalSlice from "./slices/modalSlice";
import activeCitySlice from "./slices/activeCitySlice";

export const store = configureStore({
  reducer: {
    media: mediaReducer,
    kwlbItem: kwlbSlice,
    contactFormStatus: formModalSlice,
    activeCity: activeCitySlice,
  },
});
