import { createSlice } from "@reduxjs/toolkit";

export const formModalSlice = createSlice({
  name: "fromModalToggle",
  initialState: {
    openForm: false,
  },
  reducers: {
    toggleContactFormModal: (state, action) => {
      state.openForm = action.payload;
    },
  },
});

export const { toggleContactFormModal } = formModalSlice.actions;

export default formModalSlice.reducer;
