import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { Provider } from "react-redux";
import { store } from "./store/store";

// import i18n (needs to be bundled ;))
import "./i18n";
import SuspenseLoader from "./components/suspenese-loader/suspense-loader";
const App = React.lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <React.Suspense fallback={<SuspenseLoader />}>
        <App />
      </React.Suspense>
    </Provider>
  </React.StrictMode>
);
